import { useEffect, useState } from "react";
// import axios from "axios";

export const useFetch = (promiseFetch) => {
  const [data, setData] = useState({ data: null, error: null, loading: true });
  useEffect(() => {
    const fetchData = async function () {
      // setLoading(true);
      try {
        const response = await promiseFetch();
        // setData(response.data);
        // console.log(response.data);
        setData({ ...data, data: response.data, loading: false });
      } catch (err) {
        // console.log(err);
        // setError(err);
        setData({ ...data, error: err });
      } finally {
        // setLoading(false);
        // setData({ ...data, loading: false });
      }
    };
    fetchData();
  }, []);

  // Many datas
  return { datos: data.data, error: data.error, loading: data.loading, setData };
};

import React, { useState, useEffect } from "react";

import { Empty, Skeleton, Button, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import Axios from "axios";
import { useFetch } from "../../hooks";
import { DateTime } from "luxon";
import Table from "../../components/Table";
import defaults from "../../constants";
import Layout from "../../components/Layout";
import { auditService } from "../../services/audits";
const Audits = () => {
  const [data, setData] = useState({
    loading: true,
    data: [],
  });
  useEffect(() => {
    const getData = async () => {
      const response = await auditService.getAllFinished();
      setData({
        loading: false,
        data: response.data,
      });
    };
    getData();
  }, []);
  const columns = [
    {
      title: "Codigo",
      dataIndex: "id",
      key: "id",
      render: (text) => <Link to={`/audit/${text}`}>{text}</Link>,
      responsive: ["md"],
    },
    {
      title: "Hotel",
      dataIndex: "property",
      key: "property",
      render: (text) => <strong>{text.name}</strong>,
      responsive: ["md"],
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <span>
          {DateTime.fromISO(text).toLocaleString(DateTime.DATETIME_SHORT)}
        </span>
      ),
    },
    {
      title: "Código Hotel",
      dataIndex: "property",
      key: "property",
      render: (text) => <span>{text.code}</span>,
    },
    {
      title: "Descargar PDF",
      dataIndex: "code",
      align: "center",
      key: "code",
      render: (text) => (
        <Popconfirm
          title="Are you sure to delete this task?"
          onConfirm={(e) => {
            e.preventDefault();
            console.log(e);
          }}
          okText="Yes"
          cancelText="No"
        >
          <div
            style={{
              height: "30px",
              width: "30px",
              borderRadius: 30,
              backgroundColor: `rgba(${defaults.colors.primaryRGB},0.2)`,
            }}
          >
            <span className="icon icon-download"></span>
          </div>
        </Popconfirm>
      ),
    },
    {
      title: "",
      key: "id",
      width: 180,
      fixed: "right",
      render: (text, record) => (
        <Button href={`/audit/${record.id}`} type="primary" block ghost>
          Show Details
        </Button>
      ),
    },
  ];
  //  TODO: change to BASE URL on .env
  // https://5cec507b77d47900143b930b.mockapi.io/vcm/:endpoint
  return (
    <Layout
      title="auditorias"
      action={
        <Link to={"/audits/create"}>
          <Button type="primary" className="">
            Nueva auditoria
          </Button>
        </Link>
      }
    >
      {data.data & (data.data.length == 0) ? (
        <Empty
          className="mt-30 mt-80-md"
          description="Lo sentimos no hay datos disponibles"
        />
      ) : (
        <Table loading={data.loading} columns={columns} data={data.data} />
      )}
    </Layout>
  );
};

export default Audits;

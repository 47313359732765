import styles from "./styles.module.sass";
const BlockContainer = ({ children, className, withScroll, ...props }) => {
  return (
    <div
      className={`${styles.container} ${className} ${
        withScroll ? styles.withScroll : ""
      }`}
      {...props}
    >
      <div className={styles.container__inner}>{children}</div>
    </div>
  );
};

export default BlockContainer;

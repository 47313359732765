import { TitleBg } from "../../../components/Titles";
import { Row, Col } from "antd";
import { filter } from "lodash";
import useMedia from "use-media";
import defaults from "../../../constants";
import { auditService } from "../../../services/audits";

const InfoLabel = ({ values, ...props }) => {
  // TODO: move to utils functions
  const isMobile = useMedia({ minWidth: defaults.breakpoints.md });
  const YES = filter(values.answers, (x) => x.value == 1) || [];
  const NO = filter(values.answers, (x) => x.value == 0) || [];
  const NA = filter(values.answers, (x) => x.value == 2) || [];
  const AE = auditService.getScore(values);
  return (
    <TitleBg type="primary" className="mt-10 mb-10">
      <Row
        type="flex"
        style={{ width: "100%" }}
        gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }}
      >
        <Col xs={{ span: 12 }} lg={8} align="end">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              <strong>
                {isMobile ? "TOTAL NUMBER OF STANDARDS" : "STANDARDS"}
              </strong>
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "150px",
              }}
            >
              <span className="primary-color">{YES.length}</span>
              <span className="primary-color">{NO.length}</span>
              <span className="primary-color">{NA.length}</span>
            </div>
          </div>
        </Col>
        <Col xs={{ span: 12 }} lg={4} align={isMobile ? "end" : "start"}>
          <span>
            <strong>Area Score</strong>&nbsp;
          </span>
          {AE}%
        </Col>
      </Row>
    </TitleBg>
  );
};

export default InfoLabel;

import React, { useState } from "react";
import { Tree, Empty } from "antd";
import styles from "./styles.module.sass";
const TreeSection = React.forwardRef(({ data, className, ...props }, ref) => {
  // const [expandedKeys, setExpandedKeys] = useState(['0-0-0', '0-0-1']);
  const [selectedKeys, setSelectedKeys] = useState([]);
  // const [autoExpandParent, setAutoExpandParent] = useState(true);
  // const TreeSection = ({ data, className }) => {
  // console.log(data);
  const onSelect = (selectedKeys, info) => {
    console.log("selected", selectedKeys, info);
    setSelectedKeys(selectedKeys);
  };

  const onCheck = (checkedKeys, info) => {
    console.log("onCheck", checkedKeys, info);
  };
  if (!data) return <Empty />;
  console.log(data)
  return (
    <div className={styles.container}>
      {/* TOTAL de AREAS:{data[0].children.length} */}
      <Tree
        {...props}
        ref={ref}
        className={`${styles.tree_selection} ${className}`}
        height={390}
        switcherIcon={<span className="icon icon-chevron-right" />}
        // defaultExpandedKeys={[data[0].key || null]}
        // defaultSelectedKeys={['a-3-9', 'c-11']}
        // defaultCheckedKeys={data[0].selectedKeys || null}
        // defaultCheckedKeys={data[0].selectedKeys}
        selectedKeys={selectedKeys}
        onSelect={onSelect}
        onCheck={onCheck}
        treeData={data}
      />
    </div>
  );
});

export default TreeSection;

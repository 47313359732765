import React, { useEffect } from "react";
import { Formik } from "formik";
import { Form, Input, FormItem, SubmitButton } from "formik-antd";
import { Row, Col, message, Button } from "antd";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import FormGroup from "../../../../components/Forms/FormGroup";
import { propertyService } from "../../../../services/property";
const initialValues = {
  name: "",
  code: "",
  manager: "",
  rooms: "",
};
const SignupSchema = Yup.object().shape({
  name: Yup.string().required("Requerido"),
  code: Yup.string().required("Requerido"),
  manager: Yup.string().required("Requerido"),
  rooms: Yup.string().required("Requerido"),
});

const CreatePropertyForm = ({ duplicate = false, edit = false, ...props }) => {
  const navigate = useNavigate();
  const handleSubmit = (formProps, actions) => {
    console.log(props.initialValues);
    // aca puedo hacer la validacion del endpoint, (si es necesario create or update)
    if (edit) {
      propertyService
        .update(props.initialValues.id, formProps)
        .then((response) => {
          message.success("Registro editado correctamente");
          actions.setSubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          actions.setSubmitting(false);
        });
      return false;
    } else if (duplicate) {
      // We pass hotel id
      propertyService
        .duplicate(props.id, formProps)
        .then((response) => {
          message.success("Registro Duplicado correctamente");
          const { data } = response.data;
          navigate("/admin/properties/" + data.id);
          actions.setSubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          actions.setSubmitting(false);
        });
      return false;
    }
    propertyService
      .create(formProps)
      .then((response) => {
        console.log(response);
        const { data } = response.data;
        navigate("/admin/properties/" + data.id);
      })
      .catch((e) => {
        actions.setSubmitting(false);
        if (e.errors) actions.setErrors(e.errors);
      });
  };
  //   asi podemos usar el mismo endpoint de Create pero... con el duplicado o con el Update
  if (duplicate || props.initialValues) {
    initialValues.id = 1; // aca debemos poner el id del registro que queremos duplicar
  }
  return (
    <Formik
      initialValues={props.initialValues || initialValues}
      validationSchema={SignupSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        {/* every formik-antd component must have the 'name' prop set: */}
        
        <Row type="flex" gutter={12} justify="start" align="bottom">
          {/* {duplicate && (
            <Col xs={12}>
              <p className='primary-color'>Selecciona las secciones, áreas y pregutas que consideres, a continuación haz clic en “guardar”.</p>
            </Col>
          )} */}

          <Col xs={12} lg={6} xl={props.initialValues ? 3 : 4}>
            <FormItem name="name" required={true}>
              <FormGroup label="Nombre comercial" bold>
                <Input name="name" size="large" />
              </FormGroup>
            </FormItem>
          </Col>
          <Col xs={12} lg={6} xl={props.initialValues ? 2 : 4}>
            <FormItem name="code" required={true}>
              <FormGroup label="Property Code" bold>
                <Input name="code" size="large" />
              </FormGroup>
            </FormItem>
          </Col>
          <Col xs={12} lg={6} xl={props.initialValues ? 3 : 4}>
            <FormItem name="manager" required={true}>
              <FormGroup label="General Manager" bold>
                <Input name="manager" size="large" />
              </FormGroup>
            </FormItem>
          </Col>
          <Col xs={12} lg={6} xl={props.initialValues ? 2 : 4}>
            <FormItem name="rooms" required={true}>
              <FormGroup label="No. Rooms" bold>
                <Input name="rooms" size="large" />
              </FormGroup>
            </FormItem>
          </Col>
          <Col
            xs={12}
            lg={props.initialValues ? 2 : 5}
            style={{ marginBottom: "24px" }}
          >
            <SubmitButton block size="large">
              {duplicate
                ? "Duplicar"
                : props.initialValues
                ? "Guardar"
                : "Guardar y continuar"}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    </Formik>
  );
};
export default CreatePropertyForm;

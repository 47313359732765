import React, { useState } from "react";

import { Menu } from "antd";
import { useAbility } from "@casl/react";
import { some } from "lodash";
import "./styles.sass";
import { Link, useLocation } from "react-router-dom";
import { MenuRoutes, rootSubmenuKeys } from "./paths";
import Logo from "../../img/iconos/logo.svg";
import { AbilityContext } from "../Can";
const { SubMenu } = Menu;

const MenuApp = () => {
  // console.log(rootSubmenuKeys);
  const ability = useAbility(AbilityContext);

  let location = useLocation();
  let isAdmin = location.pathname.includes("admin");
  // Add a condition only for admin routes convention
  const openPath = isAdmin ? location.pathname.split("/")[2] : location.pathname.split("/")[1];
  const [openKeys, setOpenKeys] = useState([openPath]);
  // console.log(openPath);
  // const isMobile = useMediaQuery("(max-width: 768px)");
  const hasPath = some(rootSubmenuKeys, { path: location.pathname });
  const onOpenChange = (currentOpenKeys) => {
    setOpenKeys(currentOpenKeys);
  };
  const map = (item) => {
    const partPermission = item.permission.split("-");
    if (ability.can(partPermission[0], partPermission[1])) {
      if (item.children && item.children.length && item.visible) {
        return (
          <SubMenu
            key={item.key}
            title={
              <span>
                {item.icon ? <i className={`icon icon--md ${item.icon} mr-5`} /> : <i className='icon icon-circle-2 icon--xxs mr-5' />}
                {item.name}
                {/* {!collapsed && item.name} */}
              </span>
            }
          >
            {item.children.map(map)}
          </SubMenu>
        );
      } else if (item.visible) {
        return (
          <Menu.Item key={item.path}>
            <Link to={item.path}>
              <span>
                {item.icon ? <i className={`icon icon--md ${item.icon}`} style={{ marginRight: 5 }} /> : <i className='icon icon-circle-2 icon--xxs mr-5' />}
                {item.name}
                {/* {!collapsed ? item.name : !item.icon ? item.name : null} */}
              </span>
            </Link>
          </Menu.Item>
        );
      }
    } else {
      return null;
    }
  };
  let menu = MenuRoutes.map(map);
  return (
    <>
      <div className='menu--brand'>
        <img src={Logo} alt='' />
      </div>
      <Menu
        theme='dark'
        className='custom__menu'
        inlineIndent={28}
        onOpenChange={onOpenChange}
        selectedKeys={[
          hasPath
            ? location.pathname
            : location.pathname.split("/").length <= 3
            ? "/" + location.pathname.split("/")[1]
            : location.pathname.substring(0, location.pathname.lastIndexOf("/")).replace(/editar|agregar/gi, ""),
        ]}
        openKeys={openKeys}
        mode='inline'
      >
        {menu}
      </Menu>
    </>
  );
};

export default MenuApp;

import React, { useState, useRef, useEffect } from "react";
import {
  Empty,
  Skeleton,
  Button,
  Row,
  Col,
  Select,
  message,
  notification,
  Spin,
  Divider,
  Alert,
  Tag,
  InputNumber,
  List,
} from "antd";
import { useNavigate } from "react-router-dom";
import { some, map } from "lodash";
import Layout from "../../../components/Layout";
import FormGroup from "../../../components/Forms/FormGroup";
import { userService } from "../../../services/user";
import { propertyService } from "../../../services/property";
import { auditService } from "../../../services/audits";
import BlockContainer from "../../../components/BlockContainer";
const AuditsCreate = () => {
  const [info, setInfo] = useState({
    loading: true,
    loadingSections: false,
    hotels: [],
    users: [],
    // rooms: ["555", "777", "888", "999"],
  });
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const reqData = await userService.getAllRaw("auditor");
      const reqHotels = await propertyService.getAllRaw();
      setInfo({
        ...info,
        currentAuditor: "",
        currentHotel: "",
        users: reqData.data,
        hotels: reqHotels.data.data,
        sections: [],
        loading: false,
      });
    };
    getData();
  }, []);
  const handleSubmit = async () => {
    let dataToSend = {
      property_id: info.currentHotel,
      user_id: info.currentAuditor,
    };
    if (info.tags && info.tags.length > 0 && info.hasRooms) {
      dataToSend.rooms = info.tags;
    } else if (info.tags && info.hasRooms) {
      notification.warning({
        message: "Debes indicar al menos una habitacion a auditar",
      });
      return false;
    }
    setInfo({ ...info, loadingSections: true });
    const req = await auditService.create(dataToSend);
    if (req.data) {
      setInfo({ ...info, loadingSections: false });
      message.success("Auditoria creada con éxito");
      navigate("/audits/scheduled");
    }
    console.log("mandamos al endpoint");
  };

  const saveInputRef = useRef(null);

  const showInput = () => {
    setInfo({ ...info, inputVisible: true });
  };
  const handleInputChange = (e) => {
    setInfo({ ...info, inputValue: e });
  };
  const handleInputConfirm = () => {
    const { inputValue } = info;

    let { tags } = info;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    setInfo({
      ...info,
      tags,
      inputVisible: false,
      inputValue: "",
    });
  };
  const handleClose = (removedTag) => {
    const tags = info.tags.filter((tag) => tag !== removedTag);
    setInfo({ ...info, tags });
  };
  return (
    <Layout title="Programar Auditoria">
      <Spin tip="Loading..." spinning={info.loadingSections}>
        <Skeleton loading={info.loading} paragraph={{ rows: 20 }}>
          <div titulo="Secciones" tabkey="secciones" forceRender={true}>
            <Row type="flex" align="start" justify="start" gutter={[16, 16]}>
              <Col xs={12} lg={3}>
                {/* <FormItem
                        name="auditor"
                        required={true}
                        showValidateSuccess={true}
                      > */}
                <FormGroup label="Auditor" bold>
                  <Select
                    onChange={(value) => {
                      setInfo((prevState) => {
                        return {
                          ...prevState,
                          currentAuditor: value,
                        };
                      });
                    }}
                    name="auditor"
                    placeholder="Select a person"
                    style={{ width: "100%" }}
                    size="large"
                    value={info.currentAuditor}
                  >
                    {info.users.map((ele) => (
                      <Select.Option value={ele.id}>{ele.name}</Select.Option>
                    ))}
                  </Select>
                </FormGroup>
                {/* </FormItem> */}
              </Col>
              <Col xs={12} lg={3}>
                {/* <FormItem
                        name="auditor"
                        required={true}
                        showValidateSuccess={true}
                      > */}
                <FormGroup label="Hotel" bold>
                  <Select
                    name="hotel"
                    placeholder="Select an hotel"
                    size="large"
                    style={{ width: "100%" }}
                    value={info.currentHotel}
                    onChange={(value) => {
                      const getData = async () => {
                        const datos = await propertyService.getProgram(value);
                        // hacer validación, si existen habitaciones, aparecer los tags
                        // console.log();
                        const hasRooms = some(datos.data.data, [
                          "title",
                          "Rooms",
                        ]);
                        console.log(datos.data.data);
                        setInfo((prevState) => {
                          if (hasRooms) {
                            return {
                              ...prevState,
                              sections: datos.data.data,
                              loadingSections: false,
                              hasRooms,
                              tags: [],
                              inputVisible: false,
                              inputValue: "",
                            };
                          }
                          return {
                            ...prevState,
                            hasRooms,
                            sections: datos.data.data,
                            loadingSections: false,
                          };
                        });
                        // console.log(datos.data.data);
                      };
                      setInfo((prevState) => {
                        return {
                          ...prevState,
                          currentHotel: value,
                          loadingSections: true,
                        };
                      });
                      getData(value);
                      // getData
                    }}
                  >
                    {info.hotels.map((ele) => (
                      <Select.Option value={ele.id}>{ele.name}</Select.Option>
                    ))}
                  </Select>
                </FormGroup>
                {/* </FormItem> */}
              </Col>
              {info.sections && info.sections.length > 0 && info.currentHotel && (
                <Col xs={12} lg={{ span: 2, offset: 4 }}>
                  <Button
                    block
                    // disabled={!info.sections || info.sections.length == 0}
                    type="primary"
                    size="large"
                    className="mt-15"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Crear Auditoria
                  </Button>
                </Col>
              )}
            </Row>
            <Row type="flex" align="start" justify="start" gutter={[12, 12]}>
              {info.tags && info.hasRooms && (
                <Col xs={12}>
                  <Divider orientation="left">Habitaciones</Divider>
                  <Alert
                    className="mb-20"
                    message="Por favor Indique el o los números de cada habitación que se auditarán con la plantilla Rooms"
                    type="info"
                  />
                  <>
                    {info.tags &&
                      info.tags.map((tag, index) => {
                        const isLongTag = tag.length > 100;

                        const tagElem = (
                          <Tag
                            className="edit-tag"
                            key={tag}
                            closable={true}
                            // closable={index !== 0}
                            onClose={() => handleClose(tag)}
                          >
                            <span>
                              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                            </span>
                          </Tag>
                        );
                        return tagElem;
                      })}
                    {info.inputVisible && (
                      <InputNumber
                        style={{ maxWidth: 90 }}
                        ref={saveInputRef}
                        type="text"
                        // size="small"
                        className="tag-input"
                        value={info.inputValue}
                        onChange={handleInputChange}
                        onBlur={handleInputConfirm}
                        // onPressEnter={handleInputConfirm}
                      />
                    )}
                    {!info.inputVisible && (
                      <Tag
                        className="site-tag-plus"
                        onClick={(e) => {
                          showInput(e);
                        }}
                      >
                        Agregar Habitación +
                      </Tag>
                    )}
                  </>
                </Col>
              )}

              {info.sections && info.sections.length > 0 ? (
                <>
                  <Col xs={12}>
                    <Divider orientation="left">Secciones a Auditar</Divider>
                  </Col>
                  {map(info.sections, (section, index) => {
                    // this is not necessary anymore
                    if (!section.children) {
                      return null;
                    }
                    return (
                      <Col xs={12} lg={4} key={section.id}>
                        <BlockContainer withScroll>
                          <List
                            size="small"
                            style={{ backgroundColor: "transparent" }}
                            header={
                              <strong className="primary-color">
                                {section.title}
                              </strong>
                            }
                            dataSource={section.children}
                            renderItem={(item) => {
                              console.log(item);
                              return <List.Item>{item.title}</List.Item>;
                            }}
                          />
                        </BlockContainer>
                      </Col>
                    );
                  })}
                </>
              ) : (
                <Col xs={12} className="text-center">
                  <Empty
                    className="mt-20 mt-60-md"
                    description="Selecciona un Hotel para cargar sus Areas y Secciones, "
                  />
                </Col>
              )}
            </Row>
          </div>
        </Skeleton>
      </Spin>
    </Layout>
  );
};

export default AuditsCreate;

// import React, { useEffect } from "react";
import { Formik } from "formik";
import { Form, Input, FormItem, SubmitButton } from "formik-antd";
import { Row, Col, message } from "antd";
import * as Yup from "yup";
import FormGroup from "../../../../components/Forms/FormGroup";
import { areasService } from "../../../../services/sectionsAreas";
// const { Link } = Typography;
const initialValues = {
  name: "",
};
const SignupSchema = Yup.object().shape({
  name: Yup.string().required("Requerido"),
});

const Create = ({ id, ...props }) => {
  const handleSubmit = (formProps, actions) => {
    areasService
      .addArea(id, formProps)
      .then((response) => {
        console.log(response.data);
        const { updateSection, areas } = props;
        const newAreas = [...areas, response.data];
        updateSection(newAreas);
        actions.resetForm();
        props.closemodal();
      })
      .catch((e) => {
        actions.setSubmitting(false);
        actions.setErrors({ name: e.error });
      });
  };
  return (
    <Formik initialValues={initialValues} validationSchema={SignupSchema} onSubmit={handleSubmit}>
      <Form>
        <Row type='flex' gutter={12} justify='center'>
          <Col xs={12}>
            <FormItem name='name' required={true}>
              <FormGroup label='Nombre del Area' bold>
                <Input name='name' size='large' />
              </FormGroup>
            </FormItem>
          </Col>
          <Col xs={12} lg={4}>
            <SubmitButton block size='large'>
              Guardar
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    </Formik>
  );
};
export default Create;

const defaults = {
  breakpoints: {
    xs: 320,
    sm: 768,
    md: 992,
    lg: 1200,
  },
  colors: {
    success: "#A5D86C",
    warning: "#FFCA29",
    danger: "#FF2B29",
    acceptable: "#A5D86C",
    belowstandard: "#FFCA29",
    critical: "#FF2B29",
    primary: "#1890FF",
    primaryRGB: "24, 144, 255",
  },
  status: {
    acceptable: [80, 100],
    below: [60, 79],
    critical: [0, 59],
  },
};

export const chartsConf = {
  pie: {
    height: 180,
    width: 100,
    autoFit: true,
    appendPadding: 10,
    // angleField: "value",
    // colorField: "type",
    angleField: "percentage",
    colorField: "status",
    xAxis: false,
    // autoFit: true,
    radius: 1,
    innerRadius: 0.5,
    label: {
      type: "inner",
      offset: "-50%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 12,
        textAlign: "center",
      },
    },
    showMarkers: false,
    legend: false, // close legend
    color: ({ status }) => {
      if (status === "Acceptable") {
        return defaults.colors.success;
      } else if (status === "Critical") {
        return defaults.colors.danger;
      }
      return defaults.colors.warning;
    },
    statistic: null,
  },
  bar: {
    width: "100%",
    autoFit: true,
    limitInPlot: false,
    xAxis: false,
    // yAxis: false,
    height: 180,
    //estos campos pueden variar
    xField: "value",
    yField: "type",
    seriesField: "value",
    color: ({ value }) => {
      if (value > 80) {
        return defaults.colors.success;
      } else if (value <= 59) {
        return defaults.colors.danger;
      }
      return defaults.colors.warning;
    },
    legend: false,
    minColumnWidth: 20,
    maxColumnWidth: 20,
    barWidthRatio: 0.60,
    // meta: {
    //   ['type']: {
    //     range: [0, 100]
    //   }
    // }
    // meta: {
    //   type: {
    //     alias: "Status",
    //   },
    // },
  },
};
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default defaults;

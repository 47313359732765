/* eslint-disable no-lone-blocks */
import React, { useEffect, useContext } from "react";
import { Formik } from "formik";
import { Form, Input, FormItem, SubmitButton } from "formik-antd";
import { Spin, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import styles from "./styles.module.sass";
import bg from "../../img/login-bg.jpg";
import Logo from "../../img/logos/logo-color.svg";
import LogoFooter from "../../img/logos/icono-lupa.svg";
import { authService } from "../../services/auth.js";
import { AbilityContext } from "../../components/Can";
const initialValues = {
  email: "",
  password: "",
};
const SignupSchema = Yup.object().shape({
  email: Yup.string().required("Requerido"),
  password: Yup.string().required("Requerido"),
});

const LoginPage = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(["opa_user"]);
  let history = useNavigate();
  const ability = useContext(AbilityContext);
  const handleSubmit = (formProps, actions) => {
    const { email, password } = formProps;
    authService
      .login(email, password)
      .then((response) => {
        console.log("LOGIN", response);
        handleLoginSuccess(response.data, false);
        // redirect to index
        history("/");
      })
      .catch((error) => {
        console.log(error);
        actions.setSubmitting(false);
        if (error.data && error.data.error) actions.setStatus(error.data.error);
        if (error.errors) {
          actions.setErrors(error.errors);
        }
      });

    function handleLoginSuccess(response, remember) {
      const expiresAt = 24 * 60; // TODO: Move to Constants
      const newRules = response.data.permissions.map((ele) => {
        const parts = ele.split("-");
        return { action: parts[0], subject: parts[1] };
      });
      ability.update(newRules);
      if (!remember) {
        console.log("LLEGA", response.data);
        const options = { path: "/" };
        setCookie("opa_user", response.data, options);
        return true;
      }

      let date = new Date();
      date.setTime(date.getTime() + expiresAt * 60 * 1000);
      const options = { path: "/", expires: date };
      setCookie("opa_user", response.data, options);

      return true;
    }
  };
  //   TODO hacer la redireccion desde private Route
  useEffect(() => {
    if (cookies["opa_user"] != undefined) removeCookie("opa_user");
  }, []);
  const year = new Date().getFullYear();
  return (
    <div
      className={styles.login}
      style={{ backgroundImage: "url(" + bg + ")" }}
    >
      <div className="login__aside">&nbsp;</div>
      <div className={styles.login__body}>
        <Spin spinning={false} tip="Cargando...">
          <div className={styles.login__brand}>
            <img src={Logo} alt="" />
          </div>
          <div className={styles.login__form}>
            <span className={styles.login__form__title}>Iniciar Sesión</span>

            <Formik
              initialValues={initialValues}
              validationSchema={SignupSchema}
              onSubmit={handleSubmit}
            >
              {(props) => {
                // console.log(props.status);
                return (
                  <Form>
                    <FormItem
                      name="email"
                      required={true}
                      showValidateSuccess={true}
                    >
                      <Input
                        prefix={
                          <span
                            style={{ color: "#1890FF" }}
                            className="icon icon-user"
                          />
                        }
                        name="email"
                        placeholder="User name"
                        size="large"
                      />
                    </FormItem>
                    <FormItem
                      name="password"
                      required={true}
                      showValidateSuccess={true}
                    >
                      <Input.Password
                        prefix={
                          <span
                            style={{ color: "#1890FF" }}
                            className="icon icon-lock"
                          />
                        }
                        name="password"
                        placeholder="Password"
                        size="large"
                      />
                    </FormItem>
                    <SubmitButton block size="large">
                      Login
                    </SubmitButton>
                    {props.status && (
                      <Alert
                        className="mt-20"
                        message={props.status}
                        type="error"
                        showIcon
                      />
                    )}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Spin>
      </div>
      <footer className={styles.login__footer}>
        {/* imagen */}
        <p>
          <img src={LogoFooter} alt="" />
        </p>
        <span>
          <>Copyright © {year} Oasis Hoteles & Resorts</>
        </span>
      </footer>
    </div>
  );
};

export default LoginPage;

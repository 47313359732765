// import Axios from "axios";
import { getCookie } from "./utils";
// this History is not necesary if redux is implemented in a future version
import apiAxios from "./apiAxios";
import { handleResponse } from "./utils";
function login(username, password) {
  return apiAxios
    .post(
      `/api/login`,
      { email: username, password },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    .then((response) => response)
    .catch((error) => {
      // console.log("catch error in service", error);
      return handleResponse(error);
    });
}

// function register(user, token) {
//   const requestOptions = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(user),
//   };

//   return fetch(`${process.env.REACT_APP_BASE_URL_API}/register`, requestOptions).then(handleResponse);
// }

export const authHeader = (onlyToken = false) => {
  const store = getCookie("opa_user");
  const obj = JSON.parse(store);
  if (obj && obj.token) {
    if (onlyToken) return { Authorization: `Bearer ${obj.token}` };
    return {
      Authorization: `Bearer ${obj.token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }
  return null;
};

export const authService = {
  login,
};

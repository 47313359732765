import React, { useEffect, useState } from "react";
import { Row, Col, Select, Empty, Divider, DatePicker, Spin } from "antd";
import { Pie, Bar, Column, G2 } from "@ant-design/plots";
import { useNavigate } from "react-router-dom";
import { map } from "lodash";
import { DateTime } from "luxon";
import FormGroup from "../../../components/Forms/FormGroup";
import BlockContainer from "../../../components/BlockContainer";
import defaults, { chartsConf } from "../../../constants";
import { TitleH2 } from "../../../components/Titles";
import TagsIndicator, { TagsIndicatorsDefaults } from "../TagsIndicators/";
import Layout from "../../../components/Layout";
import { auditService } from "../../../services/audits";
import loader from "../../../img/iconos/lodaer-chart.gif";
const { Option } = Select;
const { RangePicker } = DatePicker;
const Dashboard = (props) => {
  const [data, setData] = useState({ loading: true, datos: [] });
  const [dates, setDates] = useState([]);
  const [hackValue, setHackValue] = useState();
  const [value, setValue] = useState();
  G2.registerInteraction("hover-cursor", {
    showEnable: [
      { trigger: "element:mouseenter", action: "cursor:pointer" },
      { trigger: "element:mouseleave", action: "cursor:default" },
    ],
  });
  const onOpenChange = (open) => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };
  useEffect(() => {
    const getData = async () => {
      const res = await auditService.reportByAuditor();
      console.log(res.data);
      setData({
        ...data,
        datos: res.data,
        loading: false,
      });
    };
    getData();
  }, []);
  useEffect(() => {
    if (
      dates &&
      dates.length != 0 &&
      dates[0] != undefined &&
      dates[1] != undefined
    ) {
      const [startDate, endDate] = dates;
      setData({
        ...data,
        loading: true,
      });
      const getData = async () => {
        const res = await auditService.reportByAuditor(
          `?from=${startDate.format("YYYY-MM-DD")}&to=${endDate.format(
            "YYYY-MM-DD"
          )}`
        );
        setData({
          ...data,
          datos: res.data,
          loading: false,
        });
      };
      getData();
    }
  }, [dates]);
  let navigate = useNavigate();
  return (
    <Layout title="Dashboard">
      {data.loading ? (
        <div style={{ height: "100%", width: "100%", textAlign: "center" }}>
          <Spin
            indicator={
              <img
                src={loader}
                style={{
                  marginTop: "20%",
                  width: "100%",
                  height: "100%",
                  maxWidth: "50%",
                  opacity: "0.5",
                }}
              />
            }
          />
        </div>
      ) : (
        <div style={{ maxWidth: "88%", margin: "0 auto" }}>
          <Row
            type="flex"
            justify="space-between"
            gutter={[
              { xs: 8, sm: 16, md: 24, lg: 16 },
              { xs: 8, sm: 16, md: 24, lg: 16 },
            ]}
          >
            <Col xs={12} sm={6} xl={3}>
              <FormGroup label="Rango de fechas" type="block" bold="true">
                <RangePicker
                  // disabled={data.datos && data.datos.length == 0}
                  value={hackValue || value}
                  onCalendarChange={(val) => setDates(val)}
                  onChange={(val) => setValue(val)}
                  onOpenChange={onOpenChange}
                  picker="month"
                  disabledDate={(current, dateString) => {
                    //  esta es una estancia de moment :v
                    if (!dates || dates.length === 0) {
                      return false;
                    }
                    const tooLate =
                      dates[0] && current.diff(dates[0], "months") > 11;
                    const tooEarly =
                      dates[1] && dates[1].diff(current, "months") > 11;
                    return tooEarly || tooLate;
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={12} xl={6}>
              <TagsIndicatorsDefaults className="pull-right" />
            </Col>
          </Row>
          <Row gutter={20} type="flex" justify="start">
            {data.datos && Object.keys(data.datos).length ? (
              <>
                {map(data.datos, function (ele, key) {
                  console.log(ele);
                  return (
                    <Col xs={12} lg={6} key={key}>
                      <BlockContainer className="mt-20">
                        <Row type="flex" align="top" gutter={20}>
                          <Col xs={12}>
                            <span
                              style={{
                                backgroundColor: "rgba(68,68,68,0.05)",
                                padding: "2px 5px",
                                color: "rgba(68,68,68,0.55)",
                                borderRadius: 4,
                              }}
                              className="pull-right"
                            >
                              Overall Property Score:{" "}
                              <strong
                                className={
                                  ele.statistics.grandTotal > 80
                                    ? "success-color"
                                    : ele.statistics.grandTotal > 60 && ele.statistics.grandTotal < 79
                                    ? "warning-color"
                                    : "danger-color"
                                }
                              >
                                {ele.statistics.grandTotal}%
                              </strong>
                            </span>
                            <TitleH2 className="uppercase">
                              <strong>{key}</strong>
                              <p>
                                (
                                {DateTime.fromISO(ele.date).toLocaleString(
                                  DateTime.DATE_SHORT
                                )}
                                )
                              </p>
                              <small>TOTAL OF AREAS AUDITED</small>
                            </TitleH2>
                            <TagsIndicator
                              data={{
                                total: ele.statistics.total,
                                percentage: ele.statistics.percentages,
                              }}
                            />
                            {/* TODO: add Responsive condition */}
                            <Divider
                              style={{
                                marginLeft: "-1rem",
                                width: "calc(100% + 2rem)",
                              }}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            xl={4}
                            style={{ borderRight: "1px solid #DADADA" }}
                          >
                            <p>
                              <strong>Area Overall Results</strong>
                            </p>
                            <Pie
                              {...{
                                showTitle: true,
                                title: "TEST",
                                position: "center",
                                data: ele.statistics.percentages,
                                ...chartsConf.pie,
                              }}
                            />
                          </Col>
                          <Col xs={12} md={8} xl={8}>
                            <p>
                              <strong>Section Overall Results</strong>
                            </p>
                            <Bar
                              {...{
                                data: ele.statistics.percentagesByArea,
                                columnWidthRatio: 0.4,
                                ...chartsConf.bar,
                              }}
                            />
                          </Col>
                          <Col xs={12}>
                            <Divider
                              style={{
                                marginLeft: "-1rem",
                                width: "calc(100% + 2rem)",
                              }}
                            />
                            <p>
                              <strong>Property Overall Results</strong>
                            </p>
                            <Column
                              {...{
                                data: ele.compare,
                                ...chartsConf.bar,
                                xField: "month",
                                yField: "score",
                                seriesField: "score",
                                height: 250,
                                color: ({ score }) => {
                                  console.log(score);
                                  if (score > 80) {
                                    return defaults.colors.success;
                                  } else if (score > 60 && score <= 79.99) {
                                    return defaults.colors.warning;
                                  }
                                  return defaults.colors.danger;
                                },
                                // yAxis: { grid: null },
                                interactions: [
                                  { type: "element-highlight" },
                                  { type: "hover-cursor" },
                                ],
                                xAxis: {
                                  grid: null,
                                  label: {
                                    autoHide: true,
                                    autoRotate: false,
                                  },
                                },
                              }}
                              onReady={(plot) => {
                                plot.chart.on("interval:click", (...evt) => {
                                  const { id } = evt[0].data.data;
                                  navigate("dashboard/audit/" + id);
                                  console.log(evt);
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </BlockContainer>
                    </Col>
                  );
                })}
              </>
            ) : (
              <Empty
                className="mt-30 mt-100-md block-center"
                description="No hay suficientes datos o no existe ninguna auditoria"
              />
            )}
          </Row>
        </div>
      )}
    </Layout>
  );
};

export default Dashboard;

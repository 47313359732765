import React, { useState } from "react";
import { Empty, Skeleton, Row, Button, Col, Input, Divider, Form } from "antd";
import { useFetch } from "../../../../hooks";
import { isEmpty, map } from "lodash";
import Layout from "../../../../components/Layout";
import FormGroup from "../../../../components/Forms/FormGroup";
import Modal from "../../../../components/Modal";
import FormSection from "./FormSection";
import SectionItem from "./SectionItem/index.js";
import { areasService } from "../../../../services/sectionsAreas";
const AreasCreate = () => {
  // const { data, loading, error } = useFetch("https://5cec507b77d47900143b930b.mockapi.io/vcm/" + "/tours");
  const [areas, setAreas] = useState([]);
  //   this endpoint add a new area to DB and then we can add a new section to this area
  const [section, setSection] = useState("");
  const [hasError, setHasError] = useState(null);
  //   if  is editing has created receive an id
  const [hasCreated, setHasCreated] = useState("");
  const handleSubmit = () => {
    if (isEmpty(section)) {
      setHasError("Is required");
    } else {
      setHasError(null);
      areasService
        .create({ name: section })
        .then((res) => {
          console.log(res.data);
          setHasCreated(res.data); //must be an string
        })
        .catch((error) => {
          setHasError(error.error);
        });
    }
  };
  // console.log(areas);
  return (
    <Layout title='Agregar Sección y Areas'>
      {/* <Skeleton loading={loading} paragraph={{ rows: 20 }}> */}
      <Row type='flex' gutter={12} justify='start' align='bottom'>
        <Col>
          <Form.Item validateStatus={hasError ? "error" : ""} help={hasError ? hasError : null} className='no-margin'>
            {/* <Input placeholder='unavailable choice' id='error' /> */}
            <FormGroup label='Nombre de la sección' bold type='block' className='no-margin'>
              <Input
                disabled={!isEmpty(hasCreated)}
                value={section}
                size='large'
                style={{ width: 250 }}
                onChange={(e) => {
                  const { value } = e.target;
                  setSection(value);
                }}
              />
            </FormGroup>
          </Form.Item>
        </Col>
        <Col>
          <Button type='primary' size='large' onClick={handleSubmit} disabled={!isEmpty(hasCreated)}>
            Guardar
          </Button>
        </Col>
      </Row>
      {!isEmpty(hasCreated) && (
        <>
          <Divider />
          <Row type='flex' gutter={12} justify='start' align='bottom'>
            <Col xs={12}>
              <Modal button='Agregar Area' title='Agregar Area'>
                <FormSection updateSection={setAreas} areas={areas} id={hasCreated.id} />
              </Modal>
            </Col>
            {!isEmpty(areas) ? (
              <Col xs={12} className='mt-10'>
                {map(areas, (area, index) => {
                  return <SectionItem data={area} section_id={hasCreated.id} deleteArea={setAreas} areas={areas} />;
                })}
              </Col>
            ) : (
              <>
                <Col xs={12}>
                  <Empty className='mt-30 mt-80-md' description='Aun no has agregado ningún area' />
                </Col>
              </>
            )}
          </Row>
        </>
      )}
      {/* </Skeleton> */}
    </Layout>
  );
};

export default AreasCreate;

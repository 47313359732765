import { Routes, Route } from "react-router-dom";
import PrivateOutlet from "../components/PrivateRoute";
import Login from "../pages/Login/index.js";
import Dashboard from "../pages/Admin";
import Dashboard2 from "../pages/Admin/Dashboard";
import AuditsList from "../pages/Audits";
import ScheduledAuditsList from "./Audits/scheduledAudits.js";
import PendingAudits from "./Audits/pendingAudits";
import CreateAudits from "./Audits/create";
import AuditCheck from "./Audits/check/index.js";
import NoMatch from "../pages/NoMatch";
// ADMIN_ROUTES
import UserIndex from "../pages/Admin/Users";
import PropertiesIndex from "../pages/Admin/Properties";
import PropertiesCreate from "../pages/Admin/Properties/updateCreateForm/";
// Areas
import Areas from "../pages/Admin/Areas";
import AreasCreate from "../pages/Admin/Areas/create";
import AreasUpdate from "../pages/Admin/Areas/update";

const RoutesApp = () => {
  return (
    <Routes>
      <Route path='/'>
        <Route path='login' element={<Login />} />
        {/* No uso Outlet como layout :v */}
        <Route element={<PrivateOutlet />}>
          <Route index element={<Dashboard2 />} />
          <Route path='dashboard/audit/:id' element={<Dashboard />} />
        </Route>
        <Route path='audits' element={<PrivateOutlet />}>
          <Route index element={<AuditsList />} />
          <Route path='create' element={<CreateAudits />} />
          <Route path=':id' element={<PendingAudits />} />
          <Route path='area/:id' element={<AuditCheck />} />
          {/* <Route path='pending' element={<PendingAudits />} /> */}
          <Route path='scheduled' element={<ScheduledAuditsList />} />
        </Route>
        {/* Admin Routes */}
        <Route path='/admin' element={<PrivateOutlet />}>
          {/* <Route index path='' element={<Dashboard2 />} /> */}
          <Route path='users/list' element={<UserIndex />} />
          <Route path='properties/list' element={<PropertiesIndex />} />
          <Route path='properties/add' element={<PropertiesCreate />} />
          <Route path='properties/:id' element={<PropertiesCreate />} />
          <Route exact path='areas/:id/edit' element={<AreasUpdate />} />
          <Route path='areas/list' element={<Areas />} />
          <Route path='areas/add' element={<AreasCreate />} />
        </Route>
        <Route path='*' element={<NoMatch />} />
      </Route>
    </Routes>
  );
};

export default RoutesApp;

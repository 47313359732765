import React from "react";
import ReactDOM from "react-dom";
// navigation whole app
import { BrowserRouter } from "react-router-dom";
// import { Router } from "react-router-dom";
import App from "./pages/App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { CookiesProvider } from "react-cookie";
import NavigateSetter from "./components/NavigateHistory";
// import "./App.css";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <NavigateSetter />
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState, useEffect, useContext } from "react";
import { Button, Tag, message, Space, DatePicker } from "antd";
// import { useFetch } from "../../hooks";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import Table from "../../components/Table";
import DB from "../../store";
// import defaults from "../../constants";
import Layout from "../../components/Layout";
import { auditService } from "../../services/audits";
import { AbilityContext } from "../../components/Can";
import defaults from "../../constants";
import FormGroup from "../../components/Forms/FormGroup/";

const AuditsEdit = () => {
  const [data, setData] = useState({
    loading: true,
    data: [],
  });
  const [loadReport, setLoadReport] = useState({
    loading: false,
    date: null,
  });

  const ability = useContext(AbilityContext);
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await auditService.getAll();
        DB.setItem("audits", res.data)
          .then(function () {
            return DB.getItem("audits");
          })
          .then(function (value) {
            setData({
              loading: false,
              data: value,
            });
          })
          .catch(function (err) {
            alert(err);
          });
      } catch (error) {
        DB.getItem("audits")
          .then(function (value) {
            setData({
              loading: false,
              data: value,
            });
          })
          .catch(function (err) {
            console.log(err);
          });
      }
    };
    getData();
  }, []);

  const columns = [
    {
      title: "Codigo",
      dataIndex: "id",
      key: "id",
      render: (text) => <Link to={`/audits/${text}`}>{text}</Link>,
      responsive: ["md"],
    },
    {
      title: "Hotel",
      dataIndex: "property",
      key: "property",
      render: (text) => <strong>{text.name}</strong>,
      responsive: ["md"],
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <span>
          {DateTime.fromISO(text).toLocaleString(DateTime.DATETIME_SHORT)}
        </span>
      ),
    },
    {
      title: "Código Hotel",
      dataIndex: "property",
      key: "property",
      render: (text) => <span>{text.code}</span>,
    },
    {
      title: "Auditor",
      dataIndex: "user",
      key: "user",
      render: (text) => <span>{text.name}</span>,
    },
    {
      title: "Reporte",
      dataIndex: "finished_at",
      key: "finished_at",
      align: "center",
      render: (text, obj) => {
        console.log(text, obj);
        // let disabled = !tag ? "geekblue" : "green";
        return (
          <>
            {/* // I must put the correct permission */}
            {ability.can("create", "audit") ? (
              // <Popconfirm
              //   title={
              //     "Desea descargar el reporte de la propiedad:" +
              //     obj.property.name
              //   }
              //   onConfirm={(e) => {
              //     e.stopPropagation()
              //     console.log(e);
              //   }}
              //   okText="Yes"
              //   cancelText="No"
              // >
              <>
                {text ? (
                  <a
                    style={{ display: "inline-block" }}
                    href={
                      process.env.REACT_APP_BASE_URL_API +
                      "/api/reporte/" +
                      obj.id
                    }
                    download
                  >
                    <div
                      style={{
                        textAlign: "center",
                        lineHeight: "30px",
                        height: "30px",
                        width: "30px",
                        borderRadius: 30,
                        backgroundColor: `rgba(${defaults.colors.primaryRGB},0.2)`,
                      }}
                    >
                      <span className="icon icon-download"></span>
                    </div>
                  </a>
                ) : null}
              </>
            ) : (
              // </Popconfirm>
              "N/A"
            )}
          </>
        );
      },
    },

    {
      title: "Estado",
      key: "finished_at",
      dataIndex: "finished_at",
      render: (tag) => {
        let color = !tag ? "geekblue" : "green";
        const text = tag ? "Finalizadas" : "En progreso";
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: "",
      key: "id",
      width: 180,
      fixed: "right",
      render: (text, record) => (
        <Link to={`/audits/${record.id}`}>
          <Button type="primary" block ghost>
            Ver Detalles
          </Button>
        </Link>
      ),
    },
  ];
  //  TODO: change to BASE URL on .env
  const isValid = (responseBlob) => {
    const jsonMimeType = "application/json";
    const dataType = responseBlob.type;
    console.log(dataType);
    const isBlob = responseBlob instanceof Blob && dataType !== jsonMimeType;
    // const b = new Blob([JSON.stringify(data)], {
    //   type: "application/json",
    // });
    // const fr = new FileReader();
    // fr.onload = (e) => {
    //   console.log(JSON.parse(e.target.result));
    // };
    // const msg = fr.readAsText(b);
    return isBlob;
  };
  return (
    <Layout
      title="Auditorias Programadas"
      action={
        ability.can("create", "audit") ? (
          <Link to={"/audits/create"}>
            <Button type="primary" className="">
              Nueva auditoria
            </Button>
          </Link>
        ) : null
      }
    >
      <Space className="pull-right mb-15">
        <FormGroup label="Reporte mensual" type="block" bold>
          <DatePicker
            allowClear={false}
            value={loadReport.date}
            onCalendarChange={(val) =>
              setLoadReport({ ...loadReport, date: val })
            }
            onChange={(val) => setLoadReport({ ...loadReport, date: val })}
            onOpenChange={(open) => {
              if (open) {
                // setHackValue([]);
                setLoadReport({ ...loadReport, date: null });
              } else {
                // setHackValue(undefined);
              }
            }}
            picker="month"
          />
        </FormGroup>

        <Button
          type="primary"
          disabled={loadReport.loading || !loadReport.date}
          onClick={async () => {
            setLoadReport({ ...loadReport, loading: true });
            if (!loadReport.date) {
              message.info("Por favor, seleccione una fecha.");
              return false;
            }
            try {
              const res = await auditService.getSummaryReport({
                date: loadReport.date.format("YYYY-MM-DD"),
              });
              setLoadReport({ ...loadReport, loading: false, date: null });
              // const url = window.URL.createObjectURL(
              //   new Blob([res.data], { type: "application/pdf" })
              // );
              var blob = new Blob([res.data], {
                type: "application/pdf;charset=utf-8",
              });
              // ! Usamos la libreria solo para el pinshi nombre, no me acepto el setArtibute download
              saveAs(blob, loadReport.date.format("MMMM-YYYY") + "-(Resumen).pdf");
            } catch (error) {
              error.text().then((text) => {
                const res = JSON.parse(text);
                message.info(res.message);
                setLoadReport({ ...loadReport, loading: false, date: null });
              });
            }
          }}
          style={{ clear: "both", marginTop: 8 }}
        >
          <span className="icon icon-download" />
        </Button>
      </Space>

      <Table loading={data.loading} columns={columns} data={data.data} />
    </Layout>
  );
};

export default AuditsEdit;

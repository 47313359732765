import { Tag, Badge, Space } from "antd";
import styles from "./styles.module.sass";
import defaults from "../../../constants";
const TagsIndicators = ({ data }) => {
  const { percentage, acceptable, critical, below } = data;
  const { colors, status } = defaults;
  return (
    <>
      <Tag>{data.total} Areas</Tag>
      <Tag>
        <Space>
          {percentage.map(ele=>(
            <Badge
            className={styles.custom_badge}
            color={colors[ele.class_css]}
            text={`${ele.count} (${ele.percentage}%)`}
            title={ele.status}
          />
          ))}
          
          {/* <Badge
            className={styles.custom_badge}
            color={colors.warning}
            text={`${critical.number} (${critical.percentage}%)`}
          />
          <Badge
            className={styles.custom_badge}
            color={colors.danger}
            text={`${below.number} (${below.percentage}%)`}
          /> */}
        </Space>
      </Tag>
      {/* <Space className='mt-15'>
        <Badge className={styles.custom_badge} color={colors.success} text={`Acceptable(${status.acceptable[0]} - ${status.acceptable[1]}%)`} />
        <Badge className={styles.custom_badge} color={colors.warning} text={`Below (${status.below[0]} - ${status.below[1]}%)`} />
        <Badge className={styles.custom_badge} color={colors.danger} text={`Critical (${status.critical[0]} - ${status.critical[1]}%)`} />
      </Space> */}
    </>
  );
};

export const TagsIndicatorsDefaults = (props) => {
  const { colors, status } = defaults;
  return (
    <div {...props}>
      <Space className="mt-15">
        <Badge
          className={styles.custom_badge}
          color={colors.success}
          text={`Acceptable(${status.acceptable[0]} - ${status.acceptable[1]}%)`}
        />
        <Badge
          className={styles.custom_badge}
          color={colors.warning}
          text={`Below (${status.below[0]} - ${status.below[1]}%)`}
        />
        <Badge
          className={styles.custom_badge}
          color={colors.danger}
          text={`Critical (${status.critical[0]} - ${status.critical[1]}%)`}
        />
      </Space>
    </div>
  );
};

export default TagsIndicators;

import React from "react";
import { Table, Typography } from "antd";
import useMedia from "use-media";
import styles from "./styles.module.sass";
import defaults from "../../constants";
const { Text, Link } = Typography;
// Recibimos data,
const StatisticsTable = ({
  type = "primary",
  columns,
  summary = true,
  data,
  title,
  noID,
}) => {
  const isMobile = useMedia({ maxWidth: defaults.breakpoints.sm });
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "1",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "2",
    },
    {
      key: "3",
      name: "John",
      age: 42,
      address: "3",
    },
    {
      key: "4",
      name: "John",
      age: 42,
      address: "4",
    },
    {
      key: "5",
      name: "John",
      age: 42,
      address: "10",
    },
    {
      key: "7",
      name: "John",
      age: 42,
      address: "4",
    },
    {
      key: "8",
      name: "John",
      age: 42,
      address: "10",
    },
  ];

  const columnas = [
    {
      title: () => {
        return <strong style={{ color: "#1890FF" }}>Area</strong>;
      },
      dataIndex: "name",
      render: (text, record, index) => {
        // console.log(text, record, index);
        return (
          <span>{index} {text}</span>
        );
      },
      key: "name",
      width: "60%",
      className: styles.header_column,
    },
    {
      className: styles.header_column,
      title: () => {
        return <strong style={{ color: "#1890FF" }}>Score</strong>;
      },
      dataIndex: "age",
      width: 80,
      render: (text, record, index) => {
        // console.log(text, record, index);
        return (
          <a>{text < 40 ? <Text type="danger">{text}%</Text> : text + "%"}</a>
        );
      },
      key: "age",
    },
    {
      className: styles.header_column,
      title: () => {
        return <strong style={{ color: "#1890FF" }}>View</strong>;
      },
      width: 80,
      align: "center",
      dataIndex: "address",
      key: "address",
      render: (text, record, index) => {
        return (
          <Link href="https://5cec507b77d47900143b930b.mockapi.io">
            <span className="icon icon-eye" />
          </Link>
        );
      },
    },
  ];
  return (
    <div className={styles.statistics_table}>
      <div className={styles.statistics_table__header}>
        <div
          className={`${styles.statistics_table__dot} ${
            type ? styles[type] : ""
          }`}
        />
        <span className={styles.statistics_table__title}>{title ?? "N/A"}</span>
        {/* <Badge color='#f50' title='#f50' size='default' /> */}
      </div>
      <div className={styles.statistics_table__info}>
        {!noID && (
          <div>
            <Link>
              <strong>ID:</strong>
            </Link>{" "}
            <span>16464</span>
          </div>
        )}

        {/* <div>
          <Link>
            <strong>Date:</strong>{" "}
          </Link>{" "}
          <span>16464</span>
        </div> */}
      </div>
      <div className={styles.statistics_table__body}>
        {/* TOTAL: {data.length} */}
        <Table
          // sticky={true}
          onHeaderCell={(row) => {
            console.log(row);
          }}
          // tableLayout='fixed'
          scroll={{ y: 200 }}
          pagination={{
            pageSize:50,
            position: ["none", "none"],
          }}
          dataSource={data ?? dataSource}
          columns={columns ?? columnas}
          summary={(pageData) => {
            if (!summary) {
              return null;
            }
            let totalBorrow = 0;

            pageData.forEach(({ age }) => {
              totalBorrow += age;
            });

            return (
              // RC-table already accept fixex prop on sumary but, antd yet, update antd as possible
              // si de plano no se actualiza posisionar absolutamentew el footer
              <Table.Summary.Row
                className={styles.statistics_table__footer}
                // fixed="true"
              >
                <Table.Summary.Cell
                  className={`${styles.header_column} ${styles.header_column__footer}`}
                >
                  <span>
                    {!isMobile
                      ? `${title || "N/A"} Average Score`
                      : `${title || "N/A"} AVG Score`}
                  </span>
                  {/* <Link>{!isMobile ? "Arrival Average Score" : "Arrival AVG Score"}</Link> */}
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  colSpan={2}
                  className={`${styles.header_column} ${styles.header_column__footer}`}
                >
                  <span>{totalBorrow}%</span>
                  {/* <span>HOLA</span> */}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </div>
    </div>
  );
};

export default StatisticsTable;

import React, { useEffect } from "react";
// we  load all routes in our app
import Navigation from "./routes";
import { AbilityContext } from "../components/Can";
import ability from "../config/ability.js";
import { useCookies } from "react-cookie";
import { message, notification } from "antd";
import { useNavigate } from "react-router-dom";
import DB from "../store";
import { map, mapValues, some, find } from "lodash";
// import luxon from "luxon";
import { DateTime } from "luxon";
// we import all styles from antd
import "antd/dist/antd.less";
import "../styles/main.sass";
// const requestPermission = () => {
//   Notification.requestPermission((status) => {
//     console.log('Notification permission status', status)
//   })
// }
const App = () => {
  // requestPermission()
  const [cookies, setCookie, removeCookie] = useCookies(["opa_user"]);

  if (process.env.NODE_ENV !== "production") {
    window.ability = ability;
  }
  const navigate = useNavigate();
  // solo para actualizar (por si refresca la pagina)
  useEffect(() => {
    if (cookies["opa_user"]) {
      const newRules = cookies["opa_user"].permissions.map((ele) => {
        const parts = ele.split("-");
        return { action: parts[0], subject: parts[1] };
      });
      ability.update(newRules);
    }
    if (navigator.serviceWorker) {
      // Este listener solamente sirve para las auditorias exclusivamente xD
      navigator.serviceWorker.addEventListener("message", function (event) {
        console.log(event);
        if (event.data.type == "REPLAY_SUCCESS") {
          // message.success("Sincronización realizada con éxito!", 15);
          notification.success({
            duration:0,
            message: "Sincronización realizada con éxito!",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
        }
        if (event.data.type == "ADD_TO_QUEUE") {
          console.log(event.data.payload);
          // por ahora solo funciona en URL check y upload
          const isUpload = event.data.payload.url.includes("upload");
          const uuid = event.data.payload.url.split("/");
          const area_audit_id = uuid[uuid.length - 2];

          // Actualizamos nuestra BD local
          if (!isUpload) {
            DB.getItem("area-audit_" + area_audit_id)
              .then(function (values) {
                console.log(values);
                DB.getItem("audit_" + values.audits_id).then((e) => {
                  console.log(e);
                  // primero sacamos todos los values para ver is esta en rooms o en areas comunes
                  const ALL_ITEMS_AREAS = some(e.audit_areas, function (o) {
                    return find(o, function (o) {
                      return o.id == area_audit_id;
                    });
                  });

                  if (ALL_ITEMS_AREAS) {
                    // Si el ID hace match aca, el id es de areas, si no, es de rooms
                    const newData = mapValues(e.audit_areas, function (o) {
                      return map(o, function (e) {
                        if (e.id == area_audit_id) {
                          return {
                            ...e,
                            audited_at: DateTime.toLocaleString(
                              DateTime.DATE_SHORT
                            ),
                          };
                        }
                        return e;
                      });
                    });
                    // Actualizamos los datos
                    e.audit_areas = newData;
                    DB.setItem("audit_" + values.audits_id, e).then(
                      function () {
                        navigate(-1);
                      }
                    );
                  } else {
                    // Cambiamos valor local de IndexedDB (a  deep change xD)
                    const newData = mapValues(e.audit_rooms, function (o) {
                      return mapValues(o, function (e, key) {
                        if (key == "items") {
                          return map(e, function (q) {
                            if (q.id == area_audit_id) {
                              return { ...q, audited_at: new Date() };
                            } else {
                              return q;
                            }
                          });
                        }
                        return e;
                      });
                    });
                    e.audit_rooms = newData;
                    DB.setItem("audit_" + values.audits_id, e).then(
                      function () {
                        navigate(-1);
                      }
                    );
                  }
                });
              })
              .catch(function (err) {
                console.log(err);
              });
          }
          // area-audit_{NUMBER} (Cuestionario para auditar)  audits/area/582
          // audit_{NUMBER} (Lista las secciones y areas de la Auditoria) audits/26
          // audits (todas las auditorias) audits/scheduled
          // tomamos la URL de donde vino el cambio y actualizamos la BD
          message.success("Request Agregado a la cola de Sincronización");
        }
      });
    }
  }, []);
  return (
    <AbilityContext.Provider value={ability}>
      <Navigation />
    </AbilityContext.Provider>
  );
};

export default App;

/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Tabs } from "antd";
import styles from "./styles.module.sass";
const { TabPane } = Tabs;
// puedo agregarle un callback
const CustomTabs = ({ defaultActiveKey, children, titulo, large, type, ...props }) => {
  return (
    <Tabs
      {...props}
      // defaultActiveKey={`${defaultActiveKey || 1}`}
      animated={false}
      size={type !== "card" ? "large" : null}
      type={type ? type : "line"}
      className={large ? styles[("custom_tabs", "custom_tabs__full")] : styles.custom_tabs}
    >
      {React.Children.toArray(children).map((child, i) => {
        const { titulo, tabkey } = child.props;
        return (
          <TabPane tab={`${titulo}`} {...child.props} key={tabkey}>
            {child}
          </TabPane>
        );
      })}
    </Tabs>
  );
};

export default CustomTabs;

import React, { useEffect, useState } from "react";
import { Button, Row, Col, Skeleton } from "antd";
import { Pie, Bar } from "@ant-design/plots";
import { useParams, useNavigate } from "react-router-dom";
import { map } from "lodash";
import StatisticsTable from "../../components/statisticsTable";
import BlockContainer from "../../components/BlockContainer";
import TagsIndicator, { TagsIndicatorsDefaults } from "./TagsIndicators";
import defaults, { chartsConf } from "../../constants";
import { TitleH2 } from "../../components/Titles";
import { auditService } from "../../services/audits";
import Layout from "../../components/Layout";
import styles from "../../components/statisticsTable/styles.module.sass";

const Dashboard = (props) => {
  const [data, setData] = useState({ loading: true, datos: [] });
  const params = useParams();
  const navigate = useNavigate();
  const columns = [
    {
      title: () => {
        return <strong style={{ color: "#1890FF" }}>Area</strong>;
      },
      dataIndex: "area",
      key: "area",
      width: "60%",
      className: styles.header_column,
    },
    {
      title: () => {
        return <strong style={{ color: "#1890FF" }}>Score</strong>;
      },
      dataIndex: "score",
      align: "right",
      render: (text, record, index) => {
        console.log(record);
        if (record.omit == 1) {
          return (
            <span style={{ color: "gray" }} className="crossed">
              N/A
            </span>
          );
        }
        return <span className={text < 60 ? "danger-color" : ""}>{text}%</span>;
      },
      key: "score",
      width: "60%",
      className: styles.header_column,
    },
  ];
  useEffect(() => {
    const getData = async () => {
      const res = await auditService.reportById(params.id);
      console.log(res.data);
      setData({
        ...data,
        datos: res.data,
        loading: false,
      });
    };
    getData();
  }, []);
  return (
    <Layout title="Dashboard">
      <Skeleton loading={data.loading}>
        <TagsIndicatorsDefaults className="pull-right" />
        <div style={{ clear: "both" }}></div>
        <Row
          type="flex"
          gutter={[
            { xs: 8, sm: 16, md: 24, lg: 16 },
            { xs: 8, sm: 16, md: 24, lg: 16 },
          ]}
        >
          <Col xs={12} sm={6} xl={6}>
            <span
              style={{
                backgroundColor: "rgba(68,68,68,0.05)",
                padding: "2px 5px",
                color: "rgba(68,68,68,0.55)",
                borderRadius: 4,
              }}
              className="pull-right"
            >
              Overall Property Score:{" "}
              <strong
                className={
                  data.datos.grandTotal > 80
                    ? "success-color"
                    : data.datos.grandTotal > 60 && data.datos.grandTotal < 79.99
                    ? "warning-color"
                    : "danger-color"
                }
              >
                {data.datos.grandTotal}%
              </strong>
            </span>
            <TitleH2 className="uppercase mt-20 mb-15">
              <Button
                ghost
                size="default"
                style={{ color: "#1890FF" }}
                className="mr-5"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <span className="icon icon-arrow-left" />
              </Button>
              <strong>{data.datos.hotel}</strong>
              <p>
                <small>TOTAL OF AREAS AUDITED</small>
              </p>
            </TitleH2>
            {/* Son datos de prueba nada mas */}
            <TagsIndicator
              data={{
                total: data.datos.total,
                percentage: data.datos.percentages,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={10} xl={6}>
            <BlockContainer className="mb-40 mt-20">
              <Row type="flex" align="top" gutter={20}>
                <Col
                  xs={12}
                  md={4}
                  xl={4}
                  style={{ borderRight: "1px solid #DADADA" }}
                >
                  <p>
                    <strong>Area Overall Results</strong>
                  </p>
                  <Pie
                    {...{
                      showTitle: true,
                      title: "TEST",
                      position: "center",
                      data: data.datos.percentages,
                      ...chartsConf.pie,
                    }}
                  />
                </Col>
                <Col xs={12} md={8} xl={8}>
                  <p>
                    <strong>Section Overall Results</strong>
                  </p>
                  <Bar
                    {...{
                      data: data.datos.percentagesByArea,
                      columnWidthRatio: 0.4,
                      ...chartsConf.bar,
                    }}
                  />
                </Col>
              </Row>
            </BlockContainer>
          </Col>
        </Row>
        <Row
          type="flex"
          gutter={[
            { xs: 8, sm: 16, md: 24, lg: 32 },
            { xs: 8, sm: 16, md: 24, lg: 32 },
          ]}
        >
          {data.datos.data &&
            map(data.datos.data, function (ele, key) {
              return (
                <Col xs={12} sm={6} xl={3}>
                  <StatisticsTable
                    noID
                    fixed="true"
                    title={key}
                    columns={columns}
                    data={ele}
                    summary={false}
                  />
                </Col>
              );
            })}
        </Row>
      </Skeleton>
    </Layout>
  );
};

export default Dashboard;

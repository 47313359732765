import styles from "./styles.module.sass";

const FormGroup = ({ label, bold, type = "lineal", className, children, ...props }) => {
  return (
    <div {...props} className={`${styles.formGroup} ${type === "block" ? styles.formGroup_block : ""} ${className}`}>
      {label && <label className={styles.label}>{bold ? <strong>{label}</strong> : label}</label>}
      {children}
    </div>
  );
};

export default FormGroup;

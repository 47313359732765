import React, { useState, useEffect } from "react";
import {
  Empty,
  Skeleton,
  Row,
  Button,
  Col,
  Input,
  Divider,
  Form,
  message,
  Space,
} from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { isEmpty, map } from "lodash";
import Layout from "../../../../components/Layout";
import FormGroup from "../../../../components/Forms/FormGroup";
import Modal from "../../../../components/Modal";
import FormSection from "../create/FormSection";
import SectionItem from "../create/SectionItem/index.js";
import { areasService } from "../../../../services/sectionsAreas";
const AreaUpdate = () => {
  const [info, setInfo] = useState({
    data: {},
    loading: true,
    isEditable: false,
    areas: [],
    section: "",
    hasError: null,
  });
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getData = async () => {
      const datos = await areasService.show(params.id);
      console.log(datos.data.areas);
      setInfo((prevState) => {
        return {
          ...prevState,
          data: datos.data, // no es necesario pero lo dejamos por si acaso
          section: datos.data.name,
          areas: datos.data.areas,
          loading: false,
        };
      });
    };
    getData();
  }, []);
  const handleSubmit = () => {
    if (isEmpty(info.section)) {
      // setHasError("Is required");
      setInfo({ ...info, hasError: "Is required" });
    } else {
      setInfo({ ...info, hasError: null });
      areasService
        .update(params.id, { name: info.section })
        .then((res) => {
          setInfo({ ...info, isEditable: false });
          message.success("Editado correctamente");
        })
        .catch((error) => {
          setInfo({ ...info, hasError: error.error });
        });
    }
  };
  const handleUpdateAreas = (newAreas) => {
    setInfo((prevState) => {
      return { ...prevState, areas: newAreas };
    });
    // setInfo({ ...info, areas: newAreas });
  };
  // console.log(areas);
  return (
    <Layout title="Agregar Sección y Areas">
      <Skeleton loading={info.loading} paragraph={{ rows: 20 }}>
        <Row type="flex" gutter={12} justify="start" align="bottom">
          {info.isEditable ? (
            <>
              <Col>
                <Form.Item
                  validateStatus={info.hasError ? "error" : ""}
                  help={info.hasError ? info.hasError : null}
                  className="no-margin"
                >
                  {/* <Input placeholder='unavailable choice' id='error' /> */}
                  <FormGroup
                    label="Nombre de la sección"
                    bold
                    type="block"
                    className="no-margin"
                  >
                    <Input
                      //   disabled={!isEmpty(info.hasCreated)}
                      value={info.section}
                      size="large"
                      style={{ width: 250 }}
                      onChange={(e) => {
                        const { value } = e.target;
                        //   setSection(value);
                        setInfo((prevState) => {
                          return {
                            ...prevState,
                            section: value,
                          };
                        });
                      }}
                    />
                  </FormGroup>
                </Form.Item>
              </Col>
              <Col>
                {/* <Button type='primary' size='large' disabled={!isEmpty(info.hasCreated)}> */}
                <Button type="primary" size="large" onClick={handleSubmit}>
                  Guardar
                </Button>
              </Col>
            </>
          ) : (
            <Space>
              <Button
                ghost
                size="default"
                style={{ color: "#1890FF" }}
                className="mr-5"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <span className="icon icon-arrow-left" />
              </Button>
              <strong>{info.section}</strong>
              <Button
                className="ml-10"
                type="primary"
                ghost
                primary
                size="small"
                onClick={() => {
                  setInfo((prevState) => {
                    return {
                      ...prevState,
                      isEditable: true,
                    };
                  });
                }}
              >
                <span className="icon icon-pencil"></span>
              </Button>
            </Space>
          )}
        </Row>
        <>
          <Divider />
          <Row type="flex" gutter={12} justify="start" align="bottom">
            <Col xs={12}>
              <Modal button="Agregar Area" title="Agregar Area">
                <FormSection
                  areas={info.areas}
                  id={params.id}
                  updateSection={handleUpdateAreas}
                />
                {/* <FormSection updateSection={setAreas} areas={info.areas} id={info.hasCreated.id} /> */}
              </Modal>
            </Col>
            {!isEmpty(info.areas) ? (
              <Col xs={12} className="mt-10">
                {map(info.areas, (area, index) => {
                  return (
                    <SectionItem
                      data={area}
                      section_id={params.id}
                      deleteArea={handleUpdateAreas}
                      areas={info.areas}
                    />
                  );
                })}
              </Col>
            ) : (
              <>
                <Col xs={12}>
                  <Empty
                    className="mt-30 mt-80-md"
                    description="Aun no has agregado ningún area"
                  />
                </Col>
              </>
            )}
          </Row>
        </>
      </Skeleton>
    </Layout>
  );
};

export default AreaUpdate;

import { authHeader } from "./auth";
import apiAxios from "./apiAxios";
import { handleResponse } from "./utils";
function getAll(page = 1) {
  // is a Paginated endpoint
  return apiAxios
    .get(`/api/user?page=${page}`, {
      // TODO: move to interceptors
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function getAllRaw(type) {
  // is a Paginated endpoint
  return apiAxios
    .get(`/api/user${type ? `?type=${type}` : ""}`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function create(data) {
  return apiAxios
    .post("/api/user", data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function update(id, data) {
  return apiAxios
    .put(`/api/user/${id}`, data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function disable(id) {
  return apiAxios
    .get(`/api/user/${id}/active`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function getAllRoles() {
  return apiAxios
    .get("/api/roles", {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function _delete(id) {
  return apiAxios
    .delete(`/api/user/${id}`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function logout() {
  return apiAxios
    .post(
      "api/logout",
      {},
      {
        headers: authHeader(),
      }
    )
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

export const userService = {
  getAll,
  getAllRaw,
  disable,
  _delete,
  getAllRoles,
  create,
  logout,
  update,
};

import { authHeader } from "./auth";
import apiAxios from "./apiAxios";
import { handleResponse } from "./utils";
function getAll(page = 1) {
  // is a Paginated endpoint
  return apiAxios
    .get(`/api/properties?page=${page}`, {
      // TODO: move to interceptors
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function getAllRaw() {
  // is a Paginated endpoint
  return apiAxios
    .get(`/api/properties`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function show(id) {
  // is a Paginated endpoint
  return apiAxios
    .get(`/api/properties/${id}`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function create(data) {
  return apiAxios
    .post("/api/properties", data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function update(id, data) {
  return apiAxios
    .put(`/api/properties/${id}`, data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function disable(id) {
  return apiAxios
    .get(`/api/properties/${id}/active`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function _delete(id) {
  return apiAxios
    .delete(`/api/properties/${id}`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function getProgram(id, data) {
  return apiAxios
    .post(`/api/properties/${id}/program`, data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function createProgram(id, data) {
  return apiAxios
    .post(`/api/properties/${id}/assign`, data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function duplicate(id, data) {
  return apiAxios
    .post(`/api/properties/${id}/duplicate`, data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
export const propertyService = {
  getAll,
  getAllRaw,
  show,
  disable,
  _delete,
  create,
  update,
  getProgram,
  createProgram,
  duplicate
};

import { Ability } from "@casl/ability";
// import { getCookie } from "../services/utils";
// export const AppAbility = Ability;
// // Ya que solo será por roles, puede que funcione esto y no necesite la carga dinamica ya que los permisos ya estan definidos
// // TODO: refactorizar
// export default function defineRulesFor(role, update = false) {
//   const { can, rules } = new AbilityBuilder(AppAbility);
//   if (role === "admin") {
//     can("manage", "all");
//   } else if (role === "auditor") {
//     can(["read", "create"], "User");
//     can(["read", "create", "update"], "Audit");
//     can(["read"], "Property");
//   } else {
//     can(["read", "OPA"]);
//   }

//   return rules;
// }
// export function buildAbilityFor(role, update = false) {
//   //   const rol = getCookie("opa_user");
//   //   console.log(rol);
//   return new AppAbility(defineRulesFor(role, update), {
//     // https://casl.js.org/v5/en/guide/subject-type-detection
//     detectSubjectType: (object) => object.type,
//   });
// }
export default new Ability([
  {
    action: "read",
    subject: "All",
  },
]);

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { Modal, Button } from "antd";
import styles from "./styles.module.sass";

const CustomModal = ({ initOnReady = false, type, button, children, tooltip, buttonProps, ...props }) => {
  const [visible, setVisible] = useState(initOnReady);
  const onCanceled = () => {
    setVisible(false);
  };
  const childrenWithProps = React.Children.map(children, (child, index) =>
    React.cloneElement(child, {
      index,
      closemodal: () => onCanceled(),
      openmodal: () => setVisible(true),
    })
  );
  return (
    <>
      <Button
        {...buttonProps}
        type={type ? type : "primary"}
        onClick={() => {
          setVisible(true);
        }}
      >
        {button}
      </Button>
      <Modal {...props} centered className={styles.custom_modal} visible={visible} onCancel={onCanceled} maskStyle={{ backgroundColor: "rgba(255,255,255,0.75)" }} footer={null}>
        {childrenWithProps}
      </Modal>
    </>
  );
};

export default CustomModal;

// import React, { useEffect } from "react";
import { Formik } from "formik";
import { Form, Input, Select, FormItem, SubmitButton } from "formik-antd";
import { map } from "lodash";
import { Row, Col, Alert, Button } from "antd";
import * as Yup from "yup";
import FormGroup from "../../../../components/Forms/FormGroup";
import { userService } from "../../../../services/user";
// const { Link } = Typography;

const SignupSchema = Yup.object().shape({
  name: Yup.string().required("Requerido"),
  last_name: Yup.string().required("Requerido"),
  email: Yup.string().required("Requerido"),
  // phone: Yup.string().required("Requerido").nullable(),
  // password: Yup.string().required("Requerido"),
});

const CreateUserForm = ({ initialValues, roles, edit, setData, data, ...props }) => {
  const handleSubmit = async (formProps, actions) => {
    console.log(formProps);
    if (edit) {
      try {
        const res = await userService.update(initialValues.id, formProps);
        const newData = map(data, function (ele) {
          if (ele.id == initialValues.id) return res.data.data;
          return ele;
        });
        setData((prevState) => {
          return {
            ...prevState,
            data: { ...prevState.data, data: newData },
          };
        });
        actions.resetForm();
        props.closemodal();
      } catch (error) {
        console.log(error);
        actions.setSubmitting(false);
        if (error.error) actions.setStatus(error.error);
        if (error.errors) {
          actions.setErrors(error.errors);
        }
      }
      return true;
    }
    try {
      await userService.create(formProps);
      const newUsers = await userService.getAll(formProps);
      console.log(newUsers);
      // here is a Good idea fetch again because the data should be contain new pagination data
      setData((prevState) => {
        console.log(prevState);
        return {
          ...prevState,
          data: newUsers.data,
        };
      });
      actions.resetForm();
      props.closemodal();
    } catch (error) {
      console.log(error);
      actions.setSubmitting(false);
      if (error.error) actions.setStatus(error.error);
      if (error.errors) {
        actions.setErrors(error.errors);
      }
    }
  };
  // TODO: create Util
  function validate(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={
        initialValues
          ? initialValues
          : {
              name: "",
              last_name: "",
              email: "",
              phone: "",
              password: "",
            }
      }
      validationSchema={SignupSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        // console.log(props.status);
        return (
          <Form>
            {/* every formik-antd component must have the 'name' prop set: */}
            <Row type='flex' gutter={12} justify='center'>
              {props.status && (
                <Col xs={12} className='mb-20'>
                  <Alert className='mt-20' message={props.status} type='error' showIcon />
                </Col>
              )}
              <Col xs={12} lg={4}>
                <FormItem name='name' required={true}>
                  <FormGroup label='Name' bold>
                    <Input name='name' placeholder='Jhon' size='large' />
                  </FormGroup>
                </FormItem>
              </Col>
              <Col xs={12} lg={4}>
                <FormItem name='last_name' required={true}>
                  <FormGroup label='Last Name' bold>
                    <Input name='last_name' placeholder='Doe' size='large' />
                  </FormGroup>
                </FormItem>
              </Col>
              <Col xs={12} lg={4}>
                <FormItem name='email' required={true}>
                  <FormGroup label='Email' bold>
                    <Input name='email' placeholder='user@example.com' size='large' />
                  </FormGroup>
                </FormItem>
              </Col>
              <Col xs={12} lg={4}>
                <FormItem name='phone' required={true}>
                  <FormGroup label='Phone' bold>
                    <Input name='phone' placeholder='Phone' size='large' />
                  </FormGroup>
                </FormItem>
              </Col>
              <Col xs={12} lg={4}>
                <FormItem name='roles' required={true}>
                  <FormGroup label='Roles' bold>
                    <Select name='roles' placeholder='Please select an item' size='large' mode='multiple'>
                      {roles.map((ele) => (
                        <Select.Option value={ele.id}>{ele.name}</Select.Option>
                      ))}
                    </Select>
                  </FormGroup>
                </FormItem>
              </Col>
              <Col xs={12} lg={4}>
                <FormItem name='password' validate={edit ? true : validate}>
                  <FormGroup label='Password' bold>
                    <Input.Password name='password' placeholder='Input Password' size='large' />
                  </FormGroup>
                </FormItem>
              </Col>

              <Col xs={12} lg={4}>
                {/* <SubmitButton block size='large'>
                  Guardar
                </SubmitButton> */}
                <Button
                  style={{ width: 150 }}
                  size='large'
                  loading={props.isSubmitting}
                  type='primary'
                  htmlType='submit'
                  disabled={!props.isValid && (props.dirty || props.submitCount > 0) && !edit}
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};
export default CreateUserForm;

import React from "react";
import styles from "./styles.module.sass";
import { isString } from "lodash";
const TitleH1 = ({ children, noMargin = false }) => {
  return <h1 className={`${styles.title_h1} ${noMargin ? styles.title_h1_noMargin : ""}`}>{children}</h1>;
};

export const TitleH2 = ({ children, className, ...props }) => {
  return (
    <h2 {...props} className={`${styles.title_h2} ${className}`}>
      {children}
    </h2>
  );
};

export const TitleH3 = ({ children, className, noMargin = false, ...props }) => {
  return (
    <h3 {...props} className={`${styles.title_h3} ${className}`}>
      {children}
    </h3>
  );
};

export const TitleBg = ({ children, type = "default", className, noMargin = false, ...props }) => {
  // const child = isString(children)
  return <div className={`${styles.title_bg} ${type ? styles["title_bg_" + type] : ""} ${className}`}>{isString(children) ? <span>{children}</span> : children}</div>;
};

export default TitleH1;

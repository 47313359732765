import { authHeader } from "./auth";
import apiAxios from "./apiAxios";
import { handleResponse } from "./utils";
function getAll(page = 1) {
  return apiAxios
    .get(`/api/sections?page=${page}`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function getAllFormatted() {
  return apiAxios
    .get(`/api/sections-formatted`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function create(data) {
  return apiAxios
    .post("/api/sections", data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function show(id) {
  return apiAxios
    .get("/api/sections/" + id, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function update(id, data) {
  return apiAxios
    .put(`/api/sections/${id}`, data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function disable(id) {
  return apiAxios
    .get(`/api/sections/${id}/active`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function _delete(id) {
  return apiAxios
    .delete(`/api/sections/${id}`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function addArea(id, data) {
  return apiAxios
    .post(`/api/sections/${id}/area`, data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function updateArea(id, data) {
  return apiAxios
    .put(`/api/areas/${id}`, data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function deleteArea(id) {
  return apiAxios
    .delete(`/api/areas/${id}`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function addQuestion(id, data) {
  return apiAxios
    .post(`/api/areas/${id}/add-question`, data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function removeQuestion(id, data) {
  return apiAxios
    .post(`/api/areas/${id}/remove-question`, data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function updateQuestion(id, data) {
  return apiAxios
    .post(`/api/areas/${id}/update-question`, data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
export const areasService = {
  getAll,
  getAllFormatted,
  show,
  disable,
  _delete,
  create,
  update,
  addArea,
  updateArea,
  deleteArea,
  addQuestion,
  removeQuestion,
  updateQuestion,
};

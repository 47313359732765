import Axios from "axios";
import History from "../config/history";
import { delete_cookie } from "./utils";
const DEBUG = process.env.NODE_ENV === "development";
const instance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
});
// We intercept all response to handle 401 and 403 errors, all other errors are handled by the user (returned by handleResponse)

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.log("ENTRA A RESPONSE");
      if (error.response.status === 401 || error.response.status === 403) {
        console.log("ENTRA A Redirect");
        delete_cookie("opa_user");
        if (History) {
          History.push("/login");
        } else {
          // Just in case we don't have a history
          window.location.href = "/login";
        }
        if (DEBUG) console.log("handleResponse: ", error.response.data);
      }
      return Promise.reject(error);
    }
  }
);

export default instance;

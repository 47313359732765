import React, { useState } from "react";
// import { Outlet } from "react-router-dom";
import { Detector } from "react-detect-offline";
import { Layout, Drawer, Button, Badge, Tooltip } from "antd";
import styles from "./styles.module.sass";
import useMedia from "use-media";
import Menu from "../Menu/index.js";
import defaults from "../../constants";
import OverlayMenu from "../Menu/overlayMenu.js";
import HeaderTitle from "../HeaderTitle";
import LogoColor from "../../img/logos/logo-color.svg";
import LogoFooter from "../../img/logos/icono-lupa.svg";
const { Header, Content, Footer, Sider } = Layout;

const LayoutApp = ({ children, title = "N/A", action = null }) => {
  const [visible, setVisible] = useState(false);
  const isMobile = useMedia({ minWidth: defaults.breakpoints.md });
  const year = new Date().getFullYear();
  return (
    <Detector
      render={({ online }) => (
        <div className={online ? "normal" : "warning"}>
          <main className="children">
            <Layout>
              {!isMobile ? (
                <Drawer
                  title={
                    <div>
                      Menu
                      <Button
                        ghost
                        size="small"
                        onClick={() => {
                          setVisible(false);
                        }}
                        className="pull-right"
                      >
                        {/* <span className="icon icon-cross-circle" /> */}
                        <span className="icon icon-cross" />
                      </Button>
                    </div>
                  }
                  placement="left"
                  className="custom-drawer hidden-md"
                  bodyStyle={{ padding: 0, backgroundColor: "#332F3A" }}
                  closable={false}
                  onClose={() => {
                    setVisible(false);
                  }}
                  visible={visible}
                >
                  <Menu isMobile={true} />
                </Drawer>
              ) : (
                <Sider className="sider" width={250}>
                  <Menu isMobile={false} />
                </Sider>
              )}

              {/* </Drawer> */}
              <Layout>
                <Header className={styles.custom_header}>
                  <>
                    {!isMobile ? (
                      <>
                        <img style={{ maxWidth: 70 }} src={LogoColor} alt="" />
                        <div>
                        <Badge
                            status={online ? "success" : "default"}
                            text={online ? "Online" : "Offline"}
                            className="mr-10"
                          />
                        <OverlayMenu isOnline={online} noName/>
                          <Button
                            onClick={() => {
                              setVisible(!visible);
                            }}
                          >
                            {/* Menu */}
                            <span className="icon icon-menu" />
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        {!online && (
                          <Tooltip
                            title="Todos los cambios hechos Offline se verán reflejados aproximadamente 20 minutos después de restablecer la conexión"
                            color="#F0F2F5"
                            key="test"
                            overlayInnerStyle={{
                              color: "#000000",
                            }}
                          >
                            {/* TODO : move to a component */}
                            <div
                              className="primary-color"
                              style={{
                                height: "20px",
                                width: "20px",
                                lineHeight: "20px",
                                backgroundColor: "rgba(24,144,255,0.1)",
                                textAlign: "center",
                                borderRadius: 20,
                                marginRight: 10,
                              }}
                            >
                              ?
                            </div>
                          </Tooltip>
                        )}
                        <Badge
                          status={online ? "success" : "default"}
                          text={online ? "Online" : "Offline"}
                          className="mr-10"
                        />
                        <OverlayMenu isOnline={online} />
                      </>
                    )}
                  </>
                </Header>
                {/* <Header className='site-layout-sub-header-background' style={{ padding: 0 }} /> */}
                <Content>
                  <HeaderTitle title={title} action={action} />
                  <div className={styles.content}>
                    <div
                      className={styles.content__inner}
                      style={{ minHeight: "calc(100vh - 133px  )" }}
                    >
                      {/* <Outlet /> */}
                      {children}
                    </div>
                  </div>
                </Content>
                <Footer style={{ textAlign: "center" }}>
                  <p>
                    <img src={LogoFooter} alt="" />
                  </p>
                  Copyright © {year} Oasis Hoteles & Resorts
                </Footer>
              </Layout>
            </Layout>
          </main>
        </div>
      )}
    />
  );
};

export default LayoutApp;

import React from "react";
import styles from "./styles.module.sass";
import TitleH1 from "../Titles";
const HeaderTitle = ({ children, title, URL, action, type = "add", props }) => {
  // const hasURL
  return (
    <div className={styles.header_title}>
      <div className={styles.header_title_text}>{<TitleH1 noMargin>{title}</TitleH1>}</div>
      {action && <div className={styles.header_title_buttons}>{action}</div>}
    </div>
  );
};

export default HeaderTitle;

import React, { useState, useEffect } from "react";
import { Empty, Skeleton, Button, Switch, Popconfirm, message } from "antd";
import { Link } from "react-router-dom";
import { useFetch } from "../../../hooks";
import Table from "../../../components/Table";
import defaults from "../../../constants";
import Layout from "../../../components/Layout";
import Modal from "../../../components/Modal";
import NewUserForm from "./createForm";
import { userService } from "../../../services/user";
import { useCookies } from "react-cookie";
const Users = () => {
  const { datos, loading, error, setData } = useFetch(userService.getAll);
  const [roles, setRoles] = useState([]);
  // just because the data has nested data
  let { data, current_page, per_page, total } = datos || {};
  useEffect(() => {
    const getDataRoles = async () => {
      const fetchData = await userService.getAllRoles();
      setRoles(fetchData.data.data);
    };
    getDataRoles();
  }, []);
  const [cookies] = useCookies("opa_user");
  const handleStatus = (id, index, value) => {
    userService.disable(id).then((res) => {
      if (res.status === 200) {
        let newData = [...data];
        newData[index]["is_active"] = !value;
        setData((prevState) => ({
          ...prevState,
          data: { ...prevState.data, data: newData },
        }));
      }
    });
  };
  const handleDelete = (id) => {
    userService._delete(id).then(async (res) => {
      if (res.status === 200) {
        const newData = await userService.getAll();
        setData((prevState) => {
          return {
            ...prevState,
            data: newData.data,
            loading: false,
          };
        });
        message.success("User delete successfully");
      }
    });
  };
  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      render: (text) => <span className="primary-color">{text}</span>,
      //   responsive: ["md"],
    },
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
      render: (roles) => {
        return <span className='capitalize'>{roles.map((ele) => ele.name).join(" | ")}</span>;
      },
      //   responsive: ["md"],
    },
    {
      title: () => "Act/Dsc",
      key: "id",
      width: 80,
      align: "center",
      render: (data, record, index) => {
        // console.log(data, record, index);
        // console.log(cookies["opa_user"], record);
        return (
          <Switch
            disabled={cookies["opa_user"] && cookies["opa_user"].id == record.id}
            checked={data.is_active}
            onChange={() => {
              handleStatus(data.id, index, data.is_active);
            }}
          />
        );
      },
    },
    {
      title: () => "Editar",
      key: "id",
      width: 80,
      align: "center",
      render: (text, record) => {
        // console.log(record);
        return (
          <>
            <Modal
              type='link'
              buttonProps={{ className: "no-padding", href: "#" }}
              button={
                <div style={{ height: "30px", width: "30px", borderRadius: 30, backgroundColor: `rgba(${defaults.colors.primaryRGB},0.2)` }}>
                  <span className='icon icon-pencil'></span>
                </div>
              }
              title='Editar Usuario'
            >
              <NewUserForm initialValues={{ ...record, roles: record.roles.map((rol) => rol.id) }} roles={roles} setData={setData} data={data} edit />
            </Modal>
          </>
        );
      },
    },
    {
      title: () => "Eliminar",
      key: "id",
      width: 80,
      align: "center",
      render: (text, record) => (
        <Popconfirm
          disabled={cookies["opa_user"] && cookies["opa_user"].id == record.id}
          title={`Are you sure to delete this User: ${record.name}`}
          onConfirm={() => {
            handleDelete(record.id);
          }}
          okText='Yes'
          cancelText='No'
        >
          <Button type='link' href='#' className='no-padding' disabled={cookies["opa_user"] && cookies["opa_user"].id == record.id}>
            {/* Solo se usa una vez, no es necesario hacer componente, Al parecer si habia mas en ADMIN xD */}
            <div style={{ height: "30px", width: "30px", borderRadius: 30, backgroundColor: `rgba(${defaults.colors.primaryRGB},0.2)` }}>
              <span className='icon icon-trash'></span>
            </div>
          </Button>
        </Popconfirm>
      ),
    },
  ];
  //  TODO: change to BASE URL on .env
  // https://5cec507b77d47900143b930b.mockapi.io/vcm/:endpoint
  const handleTableChange = async (pagination, filters, sorter) => {
    setData((prevState) => ({
      ...prevState,
      loading: true,
    }));
    const newData = await userService.getAll(pagination.current);
    setData((prevState) => {
      return {
        ...prevState,
        data: newData.data,
        loading: false,
      };
    });
  };
  return (
    <Layout
      title='Usuarios'
      action={
        <Modal button='Nuevo Usuario' title='Nuevo Usuario'>
          <NewUserForm roles={roles} setData={setData} data={data} />
        </Modal>
      }
    >
      {error ? (
        <Empty className='mt-30 mt-80-md' description='Lo sentimos no hay datos disponibles' />
      ) : (
        <Skeleton loading={loading} paragraph={{ rows: 15 }}>
          {/* only when Table must be rendered with pagination */}
          <Table pagination={{ current: current_page, pageSize: per_page, total: total }} loading={false} columns={columns} data={data} onChange={handleTableChange} />
        </Skeleton>
      )}
    </Layout>
  );
};

export default Users;

import { DateTime, Info } from "luxon";
export const getPastYears = (date = null, limit = 10) => {
  const from = !date ? DateTime.now().minus({ years: limit }) : DateTime.fromISO(date);
  const years = Math.round(DateTime.now().diff(from, "years").years);
  const yearsBetween = [];
  for (var year = 0; year <= years; year++) yearsBetween.push(from.toObject().year + year);
  return yearsBetween;
};
export const getNameMonths = (type = "long", locale = "es") => {
  const months = Info.months(type, { locale: "es" });
  return months;
};

export function setCookie(c_name, value, expmin) {
  const exptime = new Date();
  const minutes = expmin;
  exptime.setTime(exptime.getTime() + minutes * 60 * 1000);
  const c_value = escape(value) + (expmin == null ? "" : `; expires=${exptime.toUTCString()}`);
  document.cookie = `${c_name}=${c_value}`;
}
export function getCookie(c_name) {
  let i;
  let x;
  let y;
  const ARRcookies = document.cookie.split(";");
  for (i = 0; i < ARRcookies.length; i++) {
    x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
    y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
    x = x.replace(/^\s+|\s+$/g, "");
    if (x == c_name) {
      return unescape(y);
    }
  }
}
export function delete_cookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function handleResponse(error) {
  console.log(error)
  if (error.response) {
    return Promise.reject(error.response.data);
  }
  if (error.request) {
    return Promise.reject(error.request);
  }
  return Promise.reject(error.message);
}

import { Radio, FormItem } from "formik-antd";
import { map } from "lodash";
import styles from "./styles.module.sass";
import { TitleH2 } from "../../components/Titles";
import { Tooltip } from "antd";
const StripedListAntd = ({ data, disabled, ...props }) => {
  return (
    <ul className={`${styles.list}`}>
      <li className={`${styles.list__item}`}>
        <div className={styles.list__left}>
          <strong>
            <TitleH2 className='uppercase mt-20 mb-15 no-margin'>
              <strong>ARRIVAL - STANDARDS:</strong>
            </TitleH2>
          </strong>
        </div>
        <div className={styles.list__right}>
          <div style={{ display: "flex", justifyContent: "space-between", width: "150px" }}>
            <span className='primary-color'>YES</span>
            <span className='primary-color'>NO</span>
            <span className='primary-color'>N/A</span>
          </div>
        </div>
      </li>
      {map(data, (item, index) => {
        const odd = index % 2 == 0;
        return (
          <li className={`${styles.list__item} ${!odd ? styles.list__odd : ""}`}>
            <div className={styles.list__left}>
              <strong>{index + 1}</strong> &nbsp;
              {/* TODO: validate subsstring only mobile version */}
              {item.name.length > 200 ? <Tooltip title={item.name}>{item.name.substring(0, 15)}...</Tooltip> : <span>{item.name}</span>}
            </div>
            <div className={styles.list__right}>
            <FormItem name={`answers[${index}].value`} className="no-margin">
              <Radio.Group disabled={disabled} name={`answers[${index}].value`} size='large' className={styles.list__radio} style={{ display: "flex", justifyContent: "space-between", width: "150px" }}>
                {/* 1 = SI, 0 = NO, 2 = N/A */}
                <Radio value={1}></Radio>
                <Radio value={0}></Radio>
                <Radio value={2}></Radio>
              </Radio.Group>
            </FormItem>
              
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default StripedListAntd;

import { map, union, thru, flatten } from "lodash";

const MenuRoutes = [
  {
    // TODO: Visible is a prop should be controlled by CASL utility
    visible: true,
    key: "/",
    permission: "show-audit",
    path: "/",
    name: "Dashboard",
    icon: "icon-chart-bars",
  },
  {
    visible: true,
    key: "properties",
    permission: "update-property",
    path: "/admin/properties",
    name: "Propiedades",
    icon: "icon-apartment",
    children: [
      { visible: true, permission: "update-property", name: "Lista de propiedades", path: "/admin/properties/list" },
    ],
  },
  {
    //TODO: add this permission to a Amdin rol
    visible: true,
    key: "users",
    permission: "update-property",
    path: "/admin/users",
    name: "Usuarios",
    icon: "icon-user",
    children: [
      {
        visible: true,
        permission: "update-property",
        name: "Lista de Usuarios",
        path: "/admin/users/list",
      },
    ],
  },
  {
    visible: true,
    key: "audits",
    permission: "show-audit",
    path: "/audits",
    name: "Auditorias",
    icon: "icon-eye",
    children: [
      // { visible: true, permission: "show-audit", name: "Lista de Auditorias", path: "/audits" },
      { visible: true, permission: "show-audit", name: "Lista de Auditorias", path: "/audits/scheduled" },
      // { visible: true, permission: "show-audit", name: "Auditorias pendientes", path: "/audits/pending" },
    ],
  },
  {
    visible: true,
    key: "areas",
    permission: "update-property",
    path: "/admin/areas",
    name: "Secciones y Areas",
    icon: "icon-layers",
    children: [
      { visible: true, permission: "update-property", name: "Lista de Secciones", path: "/admin/areas/list" },
    ],
  },
];
console.log(MenuRoutes.length);
const rootSubmenuKeys = flatten(thru(MenuRoutes, (coll) => union(coll, map(coll, "children") || [])));
export { MenuRoutes, rootSubmenuKeys };
